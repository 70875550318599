<template>
    <div>
        <div class="row form-group">
            <div class="col-sm-6">
                <fieldset>
                    <legend>Habitaciones y servicios</legend>
                    <div class="row form-group">
                        <label for="garantia.recamaras" class="col-form-label col-sm-3">Recamaras</label>
                        <div class="col-sm-3"><input v-model="habitacion.recamaras" type="number" name="garantia.recamaras" id="garantia.recamaras" class="form-control"></div>
                        <label for="garantia.banos" class="col-form-label col-sm-3">Baños</label>
                        <div class="col-sm-3"><input v-model="habitacion.banos" type="number" name="garantia.banos" id="garantia.banos" class="form-control"></div>
                    </div>
                    <div class="row form-group">
                        <label for="garantia.medios_banos" class="col-form-label col-sm-3">Medios baños</label>
                        <div class="col-sm-3"><input v-model="habitacion.medios_banos" type="number" name="garantia.medios_banos" id="garantia.medios_banos" class="form-control"></div>
                        <label for="garantia.estacionamientos" class="col-form-label col-sm-3">Estacionamientos</label>
                        <div class="col-sm-3"><input v-model="habitacion.estacionamientos" type="number" name="garantia.estacionamientos" id="garantia.estacionamientos" class="form-control"></div>
                    </div>
                </fieldset>
            </div>
            <div class="col-sm-6">
                <fieldset>
                    <legend>Amenidades</legend>
                    <div class="row form-group">
                        <div v-for="amenidad in amenidades" class="col-sm-4"> 

                        <VCheckbox 
                            v-model="amenidades_seleccionadas"
                            :label="amenidad.nombre"
                            :value="amenidad.valor"
                            name="garantia_amenidades[]"
                            :id="'garantia_amenidades_'+amenidad.valor"
                            @change.native="actualizar_amenidades" 
                        />

                        </div>
                    </div>
                </fieldset>
            </div>
        </div>			        
    </div>  
</template>

<script>

import VCheckbox from '@/components/Form/Checkbox';


export default {
    props:{
        garantia:{
            type: Object,
            required: true,
        },
        amenidades:{
            type: Array,
            required: true,
        },
        garantia_id:{
            type: Number,
            required: true,
        },
        impt_amenidades:null,
    },
    components:{
        VCheckbox
    },
    data(){
       return{
            habitacion:{
            recamaras:null,
            banos:null,
            medios_banos:null,
            estacionamientos:null,
            amenidades: [],
            },
			amenidades_seleccionadas: [],
       }
    },
    
    methods:{
        actualizar_amenidades(){
            this.habitacion.amenidades = [];
                this.amenidades_seleccionadas.forEach(amenidad =>{
                    if (!this.habitacion.amenidades.some(old_amenidad => old_amenidad.amenidad === amenidad)) 
                        this.habitacion.amenidades.push({garantia_id: this.garantia_id, amenidad: amenidad});
                });
            // this.$log.info(this.habitacion.amenidades);
        },
        actualizar_habitacion(){
            this.$emit('actualizar_habitacion', {habitacion: this.habitacion})
        },
        importar_datos_habitaciones(){
            this.habitacion.recamaras = this.garantia.recamaras;
            this.habitacion.banos = this.garantia.banos;
            this.habitacion.medios_banos = this.garantia.medios_banos;
            this.habitacion.estacionamientos = this.garantia.estacionamientos;
            this.amenidades_seleccionadas = this.garantia.amenidades;
            this.habitacion.amenidades = this.impt_amenidades.amenidades;
            this.amenidades_seleccionadas = this.impt_amenidades.amenidades_seleccionadas;
        },
    },
    mounted(){
        
        if (this.garantia.amenidades && this.garantia.amenidades.length > 0) {
            this.garantia.amenidades.forEach(amenidad => {
                this.amenidades_seleccionadas.push(amenidad.amenidad);
                this.habitacion.amenidades.push({garantia_id: this.garantia_id, amenidad: amenidad.amenidad});
            });
        }
        this.habitacion.recamaras = this.garantia.recamaras;
        this.habitacion.banos = this.garantia.banos;
        this.habitacion.medios_banos = this.garantia.medios_banos;
        this.habitacion.estacionamientos = this.garantia.estacionamientos;

        this.$log.info(this.amenidades);
    },
    watch:{
        habitacion:{
            handler(){
                this.actualizar_habitacion();
            },
            deep:true,
        },
        impt_amenidades:{
            handler(){
                this.importar_datos_habitaciones();
            },
            deep:true,
        }
    }
}
</script>

<style>

</style>