<template>
    <div>
        <fieldset>
            <legend>Importar Dirección Formalización</legend>
            <div class="row form-group">
                <label for="solicitud_id" class="col-form-label col-sm-2">Solicitud ID</label>
                <div class="col-sm-4"><input v-model="direccion.solicitud_id" id="solicitud_id" name="solicitud_id" type="number" class="form-control"></div>
                <div class="col-sm-6"><button class="btn btn-info btn-block"  @click="importar_direccion">Importar Dirección</button></div>                    
            </div>
        </fieldset>                      
        <fieldset>
            <legend>Dirección de la garantía</legend>
            <div class="row form-group">
                <label for="garantia.direccion.calle" class="col-form-label col-sm-2">Calle</label>
                <div class="col-sm-10"><input v-model="direccion.calle" type="text" name="direccion.calle" id="direccion.calle" class="form-control"></div>
            </div>
            <div class="row form-group">
                <label for="garantia.direccion.no_interior" class="col-form-label col-sm-2">No. interior</label>
                <div class="col-sm-4"><input v-model="direccion.no_interior" type="text" name="direccion.no_interior" id="direccion.no_interior" class="form-control"></div>
                <label for="garantia.direccion.no_exterior" class="col-form-label col-sm-2">No. Exterior</label>
                <div class="col-sm-4"><input v-model="direccion.no_exterior" type="text" name="direccion.no_exterior" id="direccion.no_exterior" class="form-control"></div>
            </div>
            <div class="row form-group">
                <label for="garantia.direccion.colonia" class="col-form-label col-sm-2">Colonia</label>
                <div class="col-sm-4"><input v-model="direccion.colonia" type="text" name="direccion.colonia" id="direccion.colonia" class="form-control"></div>
                <label for="garantia.direccion.delegacion" class="col-form-label col-sm-2">Delegación</label>
                <div class="col-sm-4"><input v-model="direccion.delegacion_municipio" type="text" name="direccion.delegacion" id="direccion.delegacion" class="form-control"></div>
            </div>
            <div class="row form-group">
                <label for="garantia.direccion.estado" class="col-form-label col-sm-2">Estado</label>
                <div class="col-sm-4">
                    <select v-model="direccion.estado" name="direccion.estado" id="direccion.estado" class="form-control">
                        <option v-for="estado in estados" :key="estado.id" :value="estado.valor">{{ estado.nombre }}</option>
                    </select>
                </div>
                <label for="garantia.direccion.cp" class="col-form-label col-sm-2">CP</label>
                <div class="col-sm-4"><input v-model="direccion.cp" type="text" name="direccion.cp" id="direccion.cp" class="form-control"></div>
            </div>
        </fieldset>    
    </div>
</template>

<script>

import apiActivos from '@/apps/garantias/api/activos';


export default {
    props:{
        estados:{
            type: Array,
            required:true,
        },
        garantia:{
            type: Object,
            required: true,
        },
        garantia_id:{
            type: Number,
            required: true,
        },         
    },
    data(){
        return{
            direccion:{
                calle: null,
                no_interior: null,
                no_exterior: null,
                colonia: null,
                delegacion_municipio: null,
                estado: null,
			    cp: null,
                solicitud_id:null,
            },
            // solicitud_id:null,
            modal_importar_direccion:false,
            garantias:[],
        }
    },
    methods:{
        actualizar_direccion(){
            this.$emit('actualizar_direccion', {direccion: this.direccion});
        },
        cancelar_importacion_direccion(){
            this.modal_importar_direccion = false;
        },
        async importar_direccion(){
            try {
                if (this.direccion.solicitud_id == null || this.direccion.solicitud_id == '') 
                    return this.$helper.showMessage('Error','Debes ingresar el ID de la solicitud','error','alert');

                let res = (await apiActivos.obtener_direccion(this.direccion.solicitud_id)).data.data[0];
                this.$log.info('direccion api', res);

                if(res=== undefined)
                    return this.$helper.showMessage('Error','El número de solicitud no tiene una garantía con dirección registrada', 'error','alert'); 
                
                let direccion = res.inmuebles;
                
                if(direccion.length === 0)
                    return this.$helper.showMessage('Error','El número de solicitud no tiene una garantía con dirección registrada', 'error','alert'); 
                
                this.direccion = {
                    calle:direccion[0].calle,
                    estado:this.$helper.normalizar(direccion[0].estado),
                    colonia:direccion[0].colonia,
                    cp: direccion[0].cp!== null ? direccion[0].cp : null,
                    delegacion_municipio:direccion[0].delegacion,
                    no_exterior: direccion[0].numero_exterior!== null ? direccion[0].numero_exterior : null,
                    no_interior: direccion[0].numero_interior!== null ? direccion[0].numero_interior : null,
                    solicitud_id:res.solicitud,
                };
                
                this.$helper.showMessage('Dirección importada','La dirección importada con éxito, no olvides guardar los datos','success');

            }catch(error) {
                this.$log.info('error',error);
                this.$helper.showAxiosError(error,'Error');
            }                            
        }
    },
    watch:{
        direccion:{
            handler(){
            this.actualizar_direccion();

            },
            deep:true,
        }
    },
    mounted(){
        this.direccion.calle = this.garantia.calle;
        this.direccion.no_interior = this.garantia.no_interior;
        this.direccion.no_exterior = this.garantia.no_exterior;
        this.direccion.colonia = this.garantia.colonia;
        this.direccion.delegacion_municipio = this.garantia.delegacion_municipio;
        this.direccion.estado = this.garantia.estado;
        this.direccion.cp = this.garantia.cp;
        this.direccion.solicitud_id = this.garantia.solicitud_id;
    }
}
</script>
