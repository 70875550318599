<template>
  <div>
    <div class="row">
        <div class="col-sm-12">
            <fieldset>
                <legend>Importar Detalles Originación</legend>
                <div class="row form-group">
                    <label for="solicitud_id" class="col-form-label col-sm-2">Solicitud ID</label>
                    <div class="col-sm-4"><input v-model="detalles.solicitud_id" id="solicitud_id" name="solicitud_id" type="number" class="form-control"></div>
                    <div class="col-sm-6"><button class="btn btn-block btn-info"  @click="buscar_garantias">Buscar Garantía</button></div>                    
                </div>
            </fieldset>           
        </div>        
        <div class="col-sm-12">
            <fieldset>
                <legend>Detalles</legend>
                <div class="row form-group">
                    <label for="garantia.descripcion" class="col-form-label col-sm-2">Descripción</label>
                    <div class="col-sm-10"><input v-model="detalles.descripcion" type="text" name="detalles.descripcion" id="detalles.descripcion" class="form-control"></div>
                </div>
                <div class="row form-group">
                    <label for="detalles.titulo_nombre" class="col-form-label col-sm-2">Título / Nombre</label>
                    <div class="col-sm-4"><input v-model="detalles.titulo_nombre" type="text" name="detalles.titulo_nombre" id="detalles.titulo_nombre" class="form-control"></div>
                    <label for="detalles.precio" class="col-form-label col-sm-2">Precio de venta</label>
                    <div class="col-sm-4"><InputCompuesto v-model="detalles.precio" label="$" name="detalles.precio" id="detalles.precio" :formats="['moneyFormat']"/></div>
                </div>
                <div class="row form-group">
                    <label for="detalles.valor" class="col-form-label col-sm-2">Valor</label>
                    <div class="col-sm-4"><InputCompuesto v-model="detalles.valor" label="$" name="detalles.valor" id="detalles.valor" :formats="['moneyFormat']" /></div>
                    <label for="detalles.superficie" class="col-form-label col-sm-2">Superficie</label>
                    <div class="col-sm-4"><InputCompuesto v-model="detalles.superficie" label_right="m2" name="detalles.superficie" id="detalles.superficie" /></div>
                </div>
                <div class="row form-group">
                    <label for="detalles.valuacion_m2" class="col-form-label col-sm-2">Valuación m2</label>
                    <div class="col-sm-4"><InputCompuesto v-model="detalles.valuacion_m2" label="$" name="detalles.valuacion_m2" id="detalles.valuacion_m2" :formats="['moneyFormat']" /></div>
                    <label for="detalles.valuacion" class="col-form-label col-sm-2">Valuación</label>
                    <div class="col-sm-4"><InputCompuesto v-model="detalles.valuacion" label="$" name="detalles.valuacion" id="detalles.valuacion" :formats="['moneyFormat']" /></div>
                </div>
                <div class="row form-group">
                    <label for="detalles.superficie_construida" class="col-form-label col-sm-2">Superficie construida</label>
                    <div class="col-sm-4"><InputCompuesto v-model="detalles.superficie_construida" label_right="m2" name="detalles.superficie_construida" id="detalles.superficie_construida" /></div>
                    <label for="detalles.antiguedad" class="col-form-label col-sm-2">Antiguedad</label>
                    <div class="col-sm-4"><InputCompuesto v-model="detalles.antiguedad" type="number" label_right="años" name="detalles.antiguedad" id="detalles.antiguedad" /></div>
                </div>
                <div class="row form-group">
                    <label for="detalles.en_garantia" class="col-form-label col-sm-2">En garantía</label>
                    <div class="col-sm-4">
                        <select v-model="detalles.en_garantia" name="detalles.en_garantia" id="detalles.en_garantia" class="form-control">
                            <option value="1">Sí</option>
                            <option value="0">No</option>
                        </select>
                    </div>
                    <label for="detalles.numero_escrituras" class="col-form-label col-sm-2">Número de escrituras</label>
                    <div class="col-sm-4"><input v-model="detalles.numero_escrituras" type="text" name="detalles.numero_escrituras" id="detalles.numero_escrituras" class="form-control"></div>
                </div>
                <div class="row form-group">
                    <label for="detalles.fecha_escrituras" class="col-form-label col-sm-2">Fecha de escrituras</label>
                    <div class="col-sm-4"><input v-model="detalles.fecha_escrituras" type="date" name="detalles.fecha_escrituras" id="detalles.fecha_escrituras" class="form-control"></div>
                    <label for="detalles.notario" class="col-form-label col-sm-2">Notario</label>
                    <div class="col-sm-4"><input v-model="detalles.notario" type="text" name="detalles.notario" id="detalles.notario" class="form-control"></div>
                </div>
                <div class="row form-group">
                    <label for="detalles.fecha_registro" class="col-form-label col-sm-2">Fecha de registro</label>
                    <div class="col-sm-4"><input v-model="detalles.fecha_registro" type="date" name="detalles.fecha_registro" id="detalles.fecha_registro" class="form-control"></div>
                    <label for="detalles.tipo_garantia" class="col-form-label col-sm-2">Tipo de garantía</label>
                    <div class="col-sm-4">
                        <select v-model="detalles.tipo_garantia" name="detalles.tipo_garantia" id="detalles.tipo_garantia" class="form-control">
                            <option v-for="tipo_garantia in tipo_garantias" :key="tipo_garantia.id" :value="tipo_garantia.valor">{{ tipo_garantia.nombre }}</option>
                        </select>
                    </div>
                </div>
                <div class="row form-group">
                    <label for="detalles.propia" class="col-form-label col-sm-2">Garantía propia</label>
                    <div class="col-sm-4">
                        <select v-model="detalles.propia" name="detalles.propia" id="detalles.propia" class="form-control">
                            <option value="1">Sí</option>
                            <option value="0">No</option>
                        </select>
                    </div>
                    <label for="detalles.existencia_gravamen" class="col-form-label col-sm-2">Existencia gravamen</label>
                    <div class="col-sm-4"><input v-model="detalles.existencia_gravamen" type="text" name="detalles.existencia_gravamen" id="detalles.existencia_gravamen" class="form-control"></div>
                </div>
                <div class="row form-group">
                    <label for="detalles.latitud" class="col-form-label col-sm-2">Latitud</label>
                    <div class="col-sm-4"><input v-model="detalles.latitud" type="text" name="detalles.latitud" id="detalles.latitud" class="form-control"></div>
                    <label for="detalles.longitud" class="col-form-label col-sm-2">Longitud</label>
                    <div class="col-sm-4"><input v-model="detalles.longitud" type="text" name="detalles.longitud" id="detalles.longitud" class="form-control"></div>
                </div>

                <div class="row form-group">
                    <div  class="col-sm-4 offset-sm-8"><button class="btn btn-block btn-dark" @click="mostrar_mapa">Ubicar Coordenadas</button></div>
                </div>
            </fieldset>
        </div>
        <div class="col-sm-12">
			<HabitacionesServicios 
                :garantia="garantia"
                :amenidades="amenidades"
                :garantia_id="garantia_id"
                @actualizar_habitacion="actualizar_datos_habitacion"
                :impt_amenidades="impt_amenidades"
            />            
        </div>
    </div>    
        <Mapa 
            v-if="mapa_modal"
            :detalles="detalles"
            @actualizar_coordenadas="actualizar_coordenadas"
            @close="cerrar_mapa"
        />

        <Detalles 
            v-if="modal_garantias_detalles"
            :garantias="garantias"
            :garantia="garantia"
            @close="cancelar_importacion_detalles"
            :garantia_id="garantia_id"
            @importar_detalles="detalles_importados"
        />

  </div>
</template>

<script>

import InputCompuesto from '@/components/Form/InputCompuesto';
import Modal from '@/components/Modal';
import Mapa from "./Mapa";
import HabitacionesServicios from "./HabitacionesServicios";
import Detalles from "./Importaciones/Detalles";

import apiActivos from '@/apps/garantias/api/activos';


export default {
    components:{
        InputCompuesto, Modal, Mapa, HabitacionesServicios, Detalles
    },
    props:{
        tipo_garantias:{
            type: Array,
            required: true,
        },
        garantia:{
            type: Object,
            required: true,
        },
        amenidades:{
            type: Array,
            required: true,
        },
        garantia_id:{
            type: Number,
            required: true,
        }
    },
    data(){
        return{
            detalles:{
                descripcion:null,
                titulo_nombre:null,
                precio:null,
                valor:null,
                superficie:null,
                valuacion_m2:null,
                valuacion:null,
                superficie_construida:null,
                antiguedad:null,
                en_garantia:null,
                numero_escrituras:null,
                fecha_escrituras:null,
                notario:null,
                fecha_registro:null,
                tipo_garantia:null,
                propia:null,
                existencia_gravamen:null,
                latitud:null,
                longitud:null,
                amenidades:[],
                recamaras:null,
                banos:null,
                medios_banos:null,
                estacionamientos:null,
                solicitud_id:null,
            },
            google: null,
            mapa_modal: false,
            solicitud_id:null,
            garantias:[],
            modal_garantias_detalles:false,
            impt_amenidades:{
                amenidades:null,
                amenidades_seleccionadas:[],
            }
        }
    },
    methods:{
        actualizar_detalles(){
            this.$emit('actualizar_detalles', {detalles: this.detalles});
        },
        mostrar_mapa(){

            if (this.detalles.latitud == '' || this.detalles.longitud == '' || this.detalles.latitud === null || this.detalles.longitud === null) 
                return this.$helper.showMessage('Error','Debes ingresar Latitud y Longitud para obtener la ubicación de las coordenadas','error','alert');
               
            this.mapa_modal = true;                     
        },
        cerrar_mapa(){
            this.mapa_modal = false;
        },
        actualizar_coordenadas(datos){
            this.detalles.latitud = datos.coordenadas.latitud;
            this.detalles.longitud = datos.coordenadas.longitud;
        },
        actualizar_datos_habitacion(datos){
            this.detalles.amenidades = datos.habitacion.amenidades;
            this.detalles.recamaras = datos.habitacion.recamaras;
            this.detalles.banos = datos.habitacion.banos;
            this.detalles.medios_banos = datos.habitacion.medios_banos;
            this.detalles.estacionamientos = datos.habitacion.estacionamientos;
        },
        async buscar_garantias(){
            try {
                if (this.detalles.solicitud_id == null || this.detalles.solicitud_id == '') 
                    return this.$helper.showMessage('Error','Debes ingresar el ID de la solicitud','error','alert');

                this.garantias = ((await apiActivos.mostrar_garantias(this.detalles.solicitud_id)).data.data);
                this.modal_garantias_detalles = true;
                // this.$log.info(this.garantias);
            }catch(error) {
                this.$log.info('error',error);
                this.$helper.showAxiosError(error,'Error');
            }     
        },
        cancelar_importacion_detalles(){
            this.modal_garantias_detalles = false;
        },
        detalles_importados(datos){
            this.$log.info(datos);

            let amenidades_impt = JSON.parse(datos.importados.amenities);
            
            let amenidades = [];

            if (amenidades_impt.length > 0) {
                amenidades_impt.forEach(amenidad => {
                    amenidades.push({garantia_id: this.garantia_id, amenidad: amenidad});
                });
                this.impt_amenidades.amenidades = amenidades;
            }

            this.impt_amenidades.amenidades_seleccionadas = amenidades_impt;

            let superficie = parseInt(datos.importados.superficie_terreno); 
            let valuacion_m2 = parseInt(datos.importados.valuacion_m2); 
            let valuacion = parseInt(datos.importados.valuacion); 
            let superficie_construida = parseInt(datos.importados.area_construida); 
            this.detalles.superficie = superficie; 
            this.detalles.valuacion_m2 = valuacion_m2; 
            this.detalles.valuacion = valuacion; 
            this.detalles.superficie_construida = superficie_construida;  
            this.detalles.antiguedad = datos.importados.edad; 
            this.detalles.propia = datos.importados.propia; 
            this.detalles.latitud = datos.importados.latitud;
            this.detalles.longitud = datos.importados.longitud;
            this.detalles.recamaras = datos.importados.recamaras;
            this.detalles.banos = datos.importados.banos;
            this.detalles.medios_banos = datos.importados.medios_banos;
            this.detalles.estacionamientos = datos.importados.estacionamientos;
            this.detalles.existencia_gravamen = datos.importados.existencia_gravamen == 1 ? 'Si' : 'No';
        },

    },
	watch:{
        detalles:{
            handler(){
            this.actualizar_detalles();
            
            },
        deep:true,
        },  
	},
    mounted(){
        this.detalles.descripcion = this.garantia.descripcion;
        this.detalles.titulo_nombre = this.garantia.titulo_nombre;
        this.detalles.precio = this.garantia.precio;
        this.detalles.valor = this.garantia.valor;
        this.detalles.superficie = this.garantia.superficie;
        this.detalles.valuacion_m2 = this.garantia.valuacion_m2;
        this.detalles.valuacion = this.garantia.valuacion;
        this.detalles.superficie_construida = this.garantia.superficie_construida;
        this.detalles.antiguedad = this.garantia.antiguedad;
        this.detalles.en_garantia = this.garantia.en_garantia;
        this.detalles.numero_escrituras = this.garantia.numero_escrituras;
        this.detalles.fecha_escrituras = this.garantia.fecha_escrituras;
        this.detalles.notario = this.garantia.notario;
        this.detalles.fecha_registro = this.garantia.fecha_registro;
        this.detalles.tipo_garantia = this.garantia.tipo_garantia;
        this.detalles.propia = this.garantia.propia;
        this.detalles.existencia_gravamen = this.garantia.existencia_gravamen;
        this.detalles.latitud = this.garantia.latitud;
        this.detalles.longitud = this.garantia.longitud;
        this.detalles.solicitud_id = this.garantia.solicitud_id;
    }
}
</script>
