<template>
	<div>
		<div class="row mt-4">
			<h1 class="col-sm-12 text-right">Garantías para venta</h1>
		</div>

		<DataGrid :config="dataGridConfig" :data="garantias" :select="seleccionar" @actions="dataGridActions">
			<div class="btn-toolbar" role="toolbar">
				<div class="btn-group mr-2" role="group">
					<button class="btn btn-secondary" @click="obtener_garantias">Recargar</button>
				</div>

				<div class="btn-group mr-2" role="group">
					<button class="btn btn-primary " @click="abrir_modal_garantia(1)">Ver Garantía</button>
					<button class="btn btn-success" @click="abrir_modal_garantia(2)">Nueva Garantía</button>
          <!--DESHABILITA BOTÓN IMPORTAR GARANTIAS-->
					<!--<button class="btn btn-info " @click="modal_excel=true">Importar Garantías</button>-->
				</div>
				<div class="btn-group mr-4" role="group">
					<button class="btn btn-dark" @click="modal_mensajes=true">Mensajes</button>
				</div>				

				<div class="btn-group" role="group">
					<select v-model="tipo_garantias" name="tipo_etapa" id="tipo_etapa" class="form-control">
						<option value="a">Ver todas las garantías</option>
						<option value="1">Ver garantías publicadas</option>
						<option value="0">Ver garantías no publicadas</option>
						<option value="2">Ver garantías vendidas</option>
					</select>
				</div>
			</div>

		</DataGrid>

	 	<Cargar v-if="modal_garantia" @close="cerrar_carga_garantia" :editar="garantia_editar"/>
		<ExcelLayout v-if="modal_excel" @close="cancelar_importacion_layout"/>
		<Mensajes v-if="modal_mensajes" @close="modal_mensajes=false" :amenidades="amenidades"/>

	</div>
</template>

<script type="text/javascript">

	import DataGrid from '@/components/DataGridV2'
	import ExcelLayout from './CargarGarantia/ExcelLayout';
	import Cargar from "./CargarGarantia/Cargar";
	import Mensajes from "./CargarGarantia/Mensajes";
	import apiActivos from '@/apps/garantias/api/activos';
	import apiOtros from '@/apps/garantias/api/otros';
	import config from "@/apps/garantias/config";


	export default {
		components: {
			DataGrid, Cargar, ExcelLayout, Mensajes 
		}
		,data: function() {
			return {
				dataGridConfig: {
					name: 'fideicoicomisos_vencidos'
					,cols: {
						id: 'ID'
            ,fideicomiso: 'Fideicomiso'
						,estatus: 'Estatus'
						,created_at: 'Fecha de creación'
					}
					,paginator: {
						pagina_actual: 1
						,total_registros: 1
						,registros_por_pagina: 20
					}
					,mutators: {
						estatus: function(val) {
							if(val==0)
								return 'Sin publicar';

							if(val==1)
								return 'Publicado en el portal';

							if(val==2)
								return 'Garantía vendida';
						}
						,created_at: function(val, row, vue) {
							return vue.$moment(val).format('YYYY-MM-DD')
						}
					}
				}
				,garantias: []
				,seleccionar: false
				,seleccionados: []
				,options: {
					page: 1
					,order_col: 'id'
					,order_dir: 'desc'
					,limit: 20
					,filters: []
				}
				,garantia: null
				,modal_garantia:false
				,modal_excel:false
				,modal_mensajes: false
				,amenidades:null
				,tipo_garantias:'a'
			}
		}
		,methods: {
			dataGridActions: function(tipo, data) {
				if (tipo == 'options') {
					this.options = data;
					this.obtener_garantias();
				}else {
					this.seleccionados = data;
					this.seleccionar = true;
				}
			}
			,obtener_garantias: async function() {
				try {
					
					let res = (await apiActivos.obtener_garantias(this.options, this.tipo_garantias)).data;
					this.garantias = res.data;
					this.dataGridConfig.paginator.pagina_actual = res.current_page;
					this.dataGridConfig.paginator.total_registros = res.total;
					this.dataGridConfig.paginator.registros_por_pagina = parseInt(res.per_page);

				}catch(e) {
					this.$log.info('error',e);
					this.$helper.showAxiosError(e,'Error');
				}
			}
			,async obtener_catalogo(){
				this.amenidades = (await apiOtros.obtener_catalogo(config.catalogos.amenidades)).data.opciones;				
			}

			,cerrar_garantia: function() {
				this.garantia = null;
				this.seleccionar = false;
			},
			async abrir_modal_garantia(opcion){
				if(opcion == 1){
					if (this.seleccionados.length == 0)
						return this.$helper.showMessage('Error','Debes seleccionar un fideicomiso o puedes cargar una Nueva Garantía', 'error','alert');

					this.garantia = this.seleccionados[0].id;
					this.garantia_editar  = (await apiActivos.obtener_garantia(this.garantia)).data;
					this.modal_garantia = true;					
				}else{
					this.garantia = null;
					this.garantia_editar = null;
					this.modal_garantia = true;
				}
			},
			cerrar_carga_garantia(){
				this.modal_garantia = 0;
				this.seleccionar = false;
			},
			cancelar_importacion_layout(){
				this.modal_excel = false;
			},
		},
		mounted(){
			this.obtener_catalogo();
		},
		watch:{
			tipo_garantias(){
				this.obtener_garantias();
			}
		}
	}
</script>

<style lang="scss">
	.dataGrid_fideicoicomisos_vencidos {
		.dataGrid-data {
			th:first-child {
				width: 20px;
			}

			th:nth-child(3), th:nth-child(4) {
				width: 15%;
			}
		}
	}
</style>