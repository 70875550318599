<template>
  <div>
    <Modal :options="{width: '90vw',close: true}" @close="cancelar_carga_garantia">
        <div class="title">{{!garantia_id ? 'Crear Garantía' : 'Detalles' }}</div>
        <div class="body">
           <ul class="nav nav-tabs mb-4">
				<li class="nav-item"><span :class="'nav-link'+(tabs_info == 1 ? ' active' : '')" @click="tabs_info = 1">Fideicomiso</span></li>
                <template v-if="garantia_id">
                    <li class="nav-item"><span :class="'nav-link'+(tabs_info == 2 ? ' active' : '')" @click="tabs_info = 2">Dirección</span></li>
                    <li class="nav-item"><span :class="'nav-link'+(tabs_info == 3 ? ' active' : '')" @click="tabs_info = 3">Detalles</span></li>
                    <li class="nav-item"><span :class="'nav-link'+(tabs_info == 5 ? ' active' : '')" @click="tabs_info = 5">Imágenes</span></li>
                </template>
			</ul>
            <Fideicomiso 
                v-if="tabs_info==1"
                :garantia="garantia"
                @actualizar_fideicomiso="actualizar_datos_fideicomiso"
             />

			<Direccion 
                v-if="tabs_info==2"
                :garantia="garantia"
                :estados="estados"
                @actualizar_direccion="actualizar_datos_direccion"
                :garantia_id="garantia_id"
            /> 

			<Detalles
                v-if="tabs_info==3"
                :garantia="garantia"
                :tipo_garantias="tipo_garantias"
                @actualizar_detalles="actualizar_datos_detalles"
                :amenidades="amenidades"
                :garantia_id="garantia_id"
            />

            <Imagen 
                v-if="tabs_info==5"
                :garantia="garantia"
                :garantia_id="garantia_id"
                @cambiar_visibilidad_imagen="obtener_garantia"
                @actualizar_imagenes="actualizar_datos_imagenes"
             />

        </div>
        <div class="footer">
            <template v-if="publicar==null">
                <div class="row">
                    <div class="col-sm-2 offset-8"><button class="btn btn-primary btn-block" @click="guardar_garantia">{{ !garantia_id ? 'Crear garantía' : 'Guardar'}}</button></div>
                    <div class="col-sm-2"><button class="btn btn-danger btn-block" @click="cancelar_carga_garantia">Cancelar</button></div>
                </div>
            </template>
            <template v-if="publicar!==null">
                <div v-if="publicar!==2" class="row">
                    <div class="col-sm-2 offset-sm-4"><button class="btn btn-block btn-dark" @click="modal_marcar_vendida=true">Marcar como vendida</button></div>                    
                    <div class="col-sm-2"><button :class="'btn '+(garantia.estatus == 1 ? 'btn-secondary btn-block' : 'btn-success btn-block')" @click="estatus_garantia">{{ garantia.estatus == 1 ? 'Ocultar garantía' : 'Publicar garantía' }}</button></div>
                    <div class="col-sm-2"><button class="btn btn-primary btn-block" @click="guardar_garantia">{{ !garantia_id ? 'Crear garantía' : 'Guardar'}}</button></div>
                    <div class="col-sm-2"><button class="btn btn-danger btn-block" @click="cancelar_carga_garantia">Cancelar</button></div>                    
                </div>
                <div v-else class="row">
                    <h3 class="col-sm-4 text-danger"><strong>Garantía vendida</strong></h3>
                    <div class="col-sm-2 offset-4"><button class="btn btn-primary btn-block" @click="guardar_garantia">{{ !garantia_id ? 'Crear garantía' : 'Guardar'}}</button></div>
                    <div class="col-sm-2"><button class="btn btn-danger btn-block" @click="cancelar_carga_garantia">Cancelar</button></div>
                </div>
            </template>
        </div>
    </Modal>

    <MarcarVendida v-if="modal_marcar_vendida" :garantia="garantia" @close="modal_marcar_vendida=false" @vendida="garantia_vendida"/>

  </div>
</template>

<script>

import apiOtros from "@/apps/garantias/api/otros";
import config from "@/apps/garantias/config";
import apiActivos from "@/apps/garantias/api/activos";

import Modal from "@/components/Modal";
import Fideicomiso from "./Fideicomiso";
import Direccion from "./Direccion";
import Detalles from "./Detalles";
import Imagen from "./Imagen";
import MarcarVendida from "./MarcarVendida";


export default {
    components: {
        Modal, Direccion, Detalles, Fideicomiso, Imagen, MarcarVendida
    },
    props:{
        editar:null,
    },
    data(){
        return{
            tabs_info: 1,
            garantia_id:null,
            garantia:{
                fideicomiso:null,
                solicitud_id:null,
				calle:null,
                no_interior:null,
                no_exterior:null,
                colonia:null,
                delegacion_municipio:null,
                estado:null,
			    cp:null,
                descripcion:null,
                titulo_nombre:null,
                precio:null,
                valor:null,
                superficie:null,
                valuacion_m2:null,
                valuacion:null,
                superficie_construida:null,
                antiguedad:null,
                en_garantia:null,
                numero_escrituras:null,
                fecha_escrituras:null,
                notario:null,
                fecha_registro:null,
                tipo_garantia:null,
                propia:null,
                existencia_gravamen:null,
                latitud:null,
                longitud:null,
				recamaras:null,
				banos:null,
				medios_banos:null,
				estacionamientos:null,
				amenidades: [],
                imagenes:[],
                estatus:0,
            },
			estados: [],
            tipo_garantias: [],
			amenidades: [],
            publicar:null,
            garantia_org:null,
            modal_marcar_vendida:false,
        }
    },
    methods:{
        async guardar_garantia(){
            try {

                if(this.garantia_id == null){
                    let datos_garantia = (await apiActivos.guardar_garantia(this.garantia)).data;
                        this.$log.info('sin id garantía');
                        this.garantia_id = datos_garantia.id;
                        this.garantia.fideicomiso = datos_garantia.fideicomiso;
                        this.garantia.solicitud_id = datos_garantia.solicitud_id;
                        this.garantia_org = JSON.stringify(this.garantia);

                        this.$helper.showMessage('Garantia creada','La garantia ha sido creada con éxito, puede contiunar llenado la información de la nueva garantía','success');
                        this.tabs_info = 2;
                }else{
                    this.$log.info('con id garantía');
                    this.garantia = (await apiActivos.editar_garantia(this.garantia_id, this.garantia)).data;
                    this.garantia_org = JSON.stringify(this.garantia);
                    this.publicar = this.garantia.estatus;
                    this.$helper.showMessage('Garantia guardada','La garantia ha sido guardada con exito','success');
                }

            }catch(error) {
                this.$log.info('error',error);
                this.$helper.showAxiosError(error,'Error');
            }

        },
		cancelar_carga_garantia(){
            // let garantia = JSON.stringify(this.garantia);
            // this.$log.info('garantia al cancelar', garantia);
            

            // if(this.garantia_id!==null){
            //     if(garantia != this.garantia_org){
            //         this.$log.info('garantia org', this.garantia_org);
            //         return this.$helper.showMessage('Error','Tienes cambios sin guardar','error','alert');
            //     }
            // }
            
            this.$emit('close');
        },
		async obtener_catalogo() {
            try {
                if (!config.catalogos) {
                    setTimeout(() => { this.obtener_catalogo()}, 500);
                    return false;
                }

                this.estados = (await apiOtros.obtener_catalogo(config.catalogos.estados)).data.opciones;
				this.tipo_garantias = (await apiOtros.obtener_catalogo(config.catalogos.tipo_garantias)).data.opciones;
				this.amenidades = (await apiOtros.obtener_catalogo(config.catalogos.amenidades)).data.opciones;

            }catch(e) {
                this.$log.info('error',e);
                this.$helper.showAxiosError(e,'Error');
            }
        },
        actualizar_datos_fideicomiso(data){
            this.garantia.fideicomiso = data.fideicomiso.fideicomiso;
            this.garantia.solicitud_id = data.fideicomiso.solicitud_id;
        },
		actualizar_datos_direccion(datos){
			this.garantia.calle = datos.direccion.calle;
			this.garantia.no_interior = datos.direccion.no_interior;
			this.garantia.no_exterior = datos.direccion.no_exterior;
			this.garantia.colonia = datos.direccion.colonia;
			this.garantia.delegacion_municipio = datos.direccion.delegacion_municipio;
			this.garantia.estado = datos.direccion.estado;
			this.garantia.cp = datos.direccion.cp;
            this.garantia.solicitud_id = datos.direccion.solicitud_id;
		},
        actualizar_datos_detalles(datos){
            this.garantia.descripcion = datos.detalles.descripcion;
            this.garantia.titulo_nombre = datos.detalles.titulo_nombre;
            this.garantia.precio = datos.detalles.precio;
            this.garantia.valor = datos.detalles.valor;
            this.garantia.superficie = datos.detalles.superficie;
            this.garantia.valuacion_m2 = datos.detalles.valuacion_m2;
            this.garantia.valuacion = datos.detalles.valuacion;
            this.garantia.superficie_construida = datos.detalles.superficie_construida;
            this.garantia.antiguedad = datos.detalles.antiguedad;
            this.garantia.en_garantia = datos.detalles.en_garantia;
            this.garantia.numero_escrituras = datos.detalles.numero_escrituras;
            this.garantia.fecha_escrituras = datos.detalles.fecha_escrituras;
            this.garantia.notario = datos.detalles.notario;
            this.garantia.fecha_registro = datos.detalles.fecha_registro;
            this.garantia.tipo_garantia = datos.detalles.tipo_garantia;
            this.garantia.propia = datos.detalles.propia;
            this.garantia.existencia_gravamen = datos.detalles.existencia_gravamen;
            this.garantia.latitud = datos.detalles.latitud;
            this.garantia.longitud = datos.detalles.longitud;
            this.garantia.amenidades = datos.detalles.amenidades;
            this.garantia.recamaras = datos.detalles.recamaras;
            this.garantia.banos = datos.detalles.banos;
            this.garantia.medios_banos = datos.detalles.medios_banos;
            this.garantia.estacionamientos = datos.detalles.estacionamientos;
            this.garantia.solicitud_id = datos.detalles.solicitud_id;
        },
        actualizar_datos_imagenes(){
            this.obtener_garantia();
            this.garantia_org = JSON.stringify(this.garantia);
        },
        async obtener_garantia(){
            try {
                this.garantia = (await apiActivos.obtener_garantia(this.garantia_id)).data;
                this.garantia_org = JSON.stringify(this.garantia);
            } catch (e) {
                this.$log.info(e);
                this.$helper.showAxiosError(e,'Error');
            }
        },
        garantia_vendida(){
            this.publicar = 2;
            this.obtener_garantia();
        },
        async estatus_garantia(){
            try {
                if(this.garantia.imagenes.length == 0 || this.garantia.estado == '' || this.garantia.tipo_garantia == '' || this.garantia.estado == null || this.garantia.tipo_garantia == null)
                    return this.$helper.showMessage('Error','Debes cumplir con los siguientes datos antes de publicar: al menos una imagen, estado y tipo de garantía','error','alert');

                this.garantia.estatus = (this.garantia.estatus == 1 ? 0 : 1);
                this.garantia = (await apiActivos.editar_garantia(this.garantia_id, this.garantia)).data;
                this.garantia_org = JSON.stringify(this.garantia);
                this.$helper.showMessage('Garantia guardada','La garantia se '+(this.garantia.estatus == 1 ? 'publicado' : 'ocultado')+' con exito','success');
            }catch(e) {
                this.$log.info('error',e);
                this.$helper.showAxiosError(e,'Error');
            }
        },
        editar_garantia(){
            // this.$log.info(this.editar);
            this.garantia = this.editar;
            this.garantia_id = this.garantia.id;
            this.publicar = this.garantia.estatus;
            // this.garantia_org = this.editar !== null ? JSON.stringify(this.editar) : JSON.stringify(this.garantia);  
        },
    },
	beforeMount(){
        this.obtener_catalogo();
        if(this.editar !== null)
            this.editar_garantia();
    },
    // updated(){
    //     this.$log.info('garantia', this.$log.info(JSON.stringify(this.garantia)));
    //     this.$log.info('garantia_org', this.$log.info(this.garantia_org));
    // }
}
</script>

<style lang="scss" scoped>

.fieldset-map {
	width: 100%;
	height: 100%;
	overflow: auto;

	#map {
		width: 100%;
		height: 100%;
	}
}

</style>