<template>
  <div>
    <Modal :options="{width: '50vw',close: true}" @close="cerrar_mapa">
        <div class="title">Ubicación de las Coordenadas</div>
        <div class="body">
            <fieldset class="fieldset-map">
                <legend>Mapa </legend>
                <div id="map"></div>
            </fieldset>
        </div>
        <div class="footer">
            <div class="text-center">
                <span> <strong>Mueve el marcador para ajustar las coordenadas en el formulario</strong></span>
            </div>
        </div>            
    </Modal>    
  </div>
</template>

<script>

import Modal from "@/components/Modal";
import gmaps from '@/helpers/gmaps';

export default {
    props:{
        detalles:{
            type: Object,
            required: true,
        }
    },
    components:{
        Modal, gmaps
    },
    data(){
        return{
            coordenadas:{
                latitud:null,
                longitud:null,
            },
            mapa_modal: false,
        }
    },
    methods:{

    },
    async mounted(){
        let that = this;
        if (!this.google) {
            this.$log.info('Cargamos google');
            this.google = await gmaps();
        }
    
        this.map = new this.google.maps.Map(document.getElementById('map'),{
            center: {
                lat: parseFloat(this.detalles.latitud),
                lng: parseFloat(this.detalles.longitud)
            },
            zoom:17
        });

        let marker = new this.google.maps.Marker({
                position: new this.google.maps.LatLng(parseFloat(this.detalles.latitud), parseFloat(this.detalles.longitud)),
                map: this.map,
                draggable:true,
                animation: google.maps.Animation.DROP,
                title: 'Garantía'
            });

        let dragend = new this.google.maps.event.addListener(marker, 'dragend', function(event) {
            that.coordenadas.latitud = event.latLng.lat().toFixed(5);
            that.coordenadas.longitud = event.latLng.lng().toFixed(5);
            that.$helper.showMessage('Coordenadas actualizadas','Se han actualizado las coordenadas correctamente','success');

        });
    },
    methods:{
         cerrar_mapa(){
            this.mapa_modal = false;
            this.$emit('close');
        },
    },
    watch:{
        coordenadas:{
            handler(){
             this.$emit('actualizar_coordenadas', {coordenadas: this.coordenadas});
            },
        deep:true,
        },
    }
           
}
</script>

<style lang="scss" scoped>

.fieldset-map {
    width: 100%;
    height: 100%;

    #map {
        width: 100%;
        height: 65vh;
    }
}

.small-display-color{
    color: #797c87;
}

</style>