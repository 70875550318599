<template>
  <div>
    <fieldset>
        <legend>Fideicomiso</legend>
        <div class="row form-group">
            <label for="fideicomiso.no_fideicomiso" class="col-form-label col-sm-2">No. Fideicomiso</label>
            <div class="col-sm-4"><input v-model="fideicomiso.fideicomiso" type="text" name="fideicomiso.no_fideicomiso" id="fideicomiso.no_fideicomiso" class="form-control"></div>
            <label for="fideicomiso.no_fideicomiso" class="col-form-label col-sm-2">No. Solicitud <small class="small-display-color">(Opcional)</small> </label>
            <div class="col-sm-4"><input v-model="fideicomiso.solicitud_id" type="text" name="fideicomiso.no_fideicomiso" id="fideicomiso.no_fideicomiso" class="form-control"></div>
        </div>
    </fieldset> 
  </div>
</template>

<script>
export default {
    props:{
        garantia: {
            type: Object,
            required: true,
        }
    },
    data(){
        return {
            fideicomiso: {
                fideicomiso: null,
                solicitud_id: null,
            }
        }
    },
    methods:{
        actualizar_fideicomiso(){
            this.$emit('actualizar_fideicomiso', {fideicomiso: this.fideicomiso});
        }
    },
    watch:{
        fideicomiso:{
            handler(){
                this.actualizar_fideicomiso();
            },
            deep:true,
        }

    },
    mounted(){
        this.fideicomiso.fideicomiso = this.garantia.fideicomiso;
        this.fideicomiso.solicitud_id = this.garantia.solicitud_id;
        // this.$log.info(this.fideicomiso);
    },
}
</script>

<style lang="scss" scoped>

.small-display-color{
    color: #797c87;
}
</style>