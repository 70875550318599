<template>
  <div>
    <Modal :options="{width: '40vw',close: true}" @click="cancelar">
        <div class="title">Importación desde Excel</div>
        <div class="body">
            <div class="form-group">
                <label for="impotar_excel">Carga un archivo para importar su información</label>
                <input type="file" @change="file_input" class="form-control-file" id="impotar_excel" accept=".xlsx">
            </div>            
        </div>
        <div class="footer">
            <div class="row">
                <div class="col-sm-4 offset-sm-6"><button class="btn secondary-btn" @click="importar_excel">Importar</button></div>
                <div class="col-sm-2"><button class="btn danger-btn" @click="cancelar">Cancelar</button></div>
            </div>
        </div>
    </Modal>      
  </div>
</template>

<script>
import Modal from '@/components/Modal'
import apiActivos from '@/apps/garantias/api/activos';

export default {
    components:{
        Modal,
    },
    data(){
        return{
            file:null,
        }
    },
    methods:{
        file_input(event){
            this.file = event.target.files[0];
        },        
        async importar_excel(){
            try {
                if(this.file === null)
                    return this.$helper.showMessage('Error','Tienes que especificar una imágen', 'error','alert');
                
                let formData = new FormData();
                    formData.append('archivo',this.file);

                await apiActivos.importar_excel(formData);
                this.cancelar();
                this.$helper.showMessage('Garantia importadas','Las garantias han importadas con éxito','success');

            } catch (e) {
                this.$log.info('error',e)
                this.$helper.showAxiosError(e,'Error')
            }                
        },
        cancelar(){
            this.file = null;
            this.$emit('close');
        },

    }

}
</script>

<style>

</style>